exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-templates-artist-page-js": () => import("./../../../src/templates/artistPage.js" /* webpackChunkName: "component---src-templates-artist-page-js" */),
  "component---src-templates-artists-js": () => import("./../../../src/templates/artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-artists-per-city-js": () => import("./../../../src/templates/artistsPerCity.js" /* webpackChunkName: "component---src-templates-artists-per-city-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

