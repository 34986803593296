export default {
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
  fonts: {
    body: 'Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Poppins',
    monospace: 'Menlo, monospace',
  },
  grids: {
    content: {
      maxWidth: '1140px',
      px: [0, null, '40px'],
      mx: ['11px', null, 'auto'],
      gap: '22px',
      gridTemplateColumns: ['repeat(4, 1fr)', null, 'repeat(16, 1fr)'],
    },
    inner: {
      gridTemplateColumns: ['repeat(4, 1fr)', null, 'repeat(12, 1fr)'],
      gridColumn: ['1/-1', null, '2/16'],
      gap: '22px',
    },
    full: {
      gap: 0,
    },
  },
  //           0   1   2   3   4   5   6   7   8   9
  fontSizes: [10, 13, 14, 16, 17, 18, 20, 24, 30, 32],
  // fontSizes: [12, 14, 16, 20, 24, 30, 48, 64, 96],
  fontWeights: {
    body: 300,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 'normal',
    heading: 1,
  },
  breakpoints: ['40em', '62em', '106em', '126em'],

  colors: {
    black: '#000',
    grayDarker: '#404040',
    grayDark: '#7C7C7C',
    gray: '#7f7f7f',
    grayLight: '#D6D6D6',
    grayLighter: '#F6F6F6',
    white: '#fff',
    red: '#DF2E2E',
    // text: 'rgb(124, 124, 124)',
    // flyout: 'rgb(214, 214, 214)',
    // border: 'rgb(246, 246, 246)',
    // white: '#fff',
    // primary: '#07c',
    // secondary: '#30c',
    // muted: '#f6f6f6',
    // heading: 'rgb(0,0,0)',
    // link: 'rgb(0,0,0)',
    // black: 'rgb(0,0,0)',
    // mainNav: 'rgb(191, 191, 191)',
  },
  buttons: {
    icon: {
      bg: 'transparent',
      margin: 0,
      padding: 0,
      cursor: 'pointer',
      '&:hover': {
        bg: 'transparent',
      },
    },
    clickableHero: {
      margin: 0,
      padding: 0,
      cursor: 'pointer',
    },
    primary: {
      margin: 2,
      mx: 'auto',
      height: '40px',
      borderRadius: '0',
      textTransform: 'uppercase',
      fontWeight: 'heading',
      fontFamily: 'heading',
      textAlign: 'center',
      color: 'black',
      bg: 'white',
      transitionProperty: 'white, color',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-in',
      '&:hover': {
        bg: 'black',
        color: 'white',
      },
      '&:focus': {
        outline: 'none',
        bg: 'black',
        color: 'white',
      },
      '&:disabled': {
        bg: 'grayDarker',
        color: 'gray',
      },
    },
    secondary: {
      color: 'white',
      bg: 'secondary',
    },
  },
  links: {
    default: {
      color: 'white',
      textDecoration: 'none',
      fontWeight: 'heading',
      textTransform: 'uppercase',

      '&.active': {
        color: 'primary',
      },
    },
    textLink: {
      color: 'inherit',
      textTransform: 'none',
    },
    artist: {
      variant: 'links.default',
      opacity: 1,
      '&.active': {
        opacity: 0.6,
      },
      '&:focus, &:hover': {
        opacity: 0.6,
        outline: 'none',
      },
    },
    filter: {
      variant: 'links.default',
      opacity: 0.6,
      '&.active': {
        opacity: 1,
      },
      '&:focus, &:hover': {
        opacity: 1,
        outline: 'none',
      },
    },
    flyout: {
      variant: 'links.default',
      color: 'gray',
      fontSize: 5,
      fontWeight: 'body',
      '&:focus, &:hover': {
        color: 'white',
        outline: 'none',
      },
    },
    mainNav: {
      fontFamily: 'inherit',
      variant: 'links.default',
      fontSize: 5,
      lineHeight: 25 / 18,
      background: 'transparent',
      fontWeight: 'body',
      color: 'gray',
      '&:visited': {
        color: 'gray',
      },
      '&:hover, &.active': {
        color: 'black',
      },
      '&:focus': {
        outline: 'none',
        variant: 'links.mainNavActive',
      },
    },
    mainNavActive: {
      fontFamily: 'inherit',
      variant: 'links.default',
      fontSize: 5,
      lineHeight: 25 / 18,
      background: 'transparent',
      fontWeight: 'body',
      color: 'black',
    },
  },

  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'body',
      textAlign: 'left',
      lineHeight: '27px',
      fontSize: 7,
      color: 'grayDark',
    },
    modalHeading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'body',
      textTransform: 'uppercase',
      textAlign: 'center',
      color: 'white',
    },
    teaserTitle: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textTransform: 'uppercase',
      fontSize: [4, null, 6],
      textAlign: 'center',
      color: 'black',
    },
    teaserSubtitle: {
      variant: 'text.teaserTitle',
      fontWeight: 'body',
    },
    title: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'body',
      textTransform: 'uppercase',
      fontSize: [5, null, 9],
      textAlign: 'center',
      color: 'white',
    },
    subtitle: {
      variant: 'text.title',
      fontWeight: 'body',
      fontSize: [3, null, 7],
    },
    legal: {
      color: 'grayDark',
      fontFamily: 'body',
      lineHeight: 'body',
      fontSize: 1,
    },
    copy: {
      color: 'grayDark',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h2: {
      color: 'black',
      fontWeight: 'body',
      fontSize: [5, null, 7],
      mt: 5,
      mb: 2,
    },
  },
  darkener: {
    default: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 100,
      pointerEvents: 'none',
    },
    'black-25': {
      variant: 'darkener.default',
      bg: 'rgba(0,0,0,0.25)',
    },
    'black-50': {
      variant: 'darkener.default',
      bg: 'rgba(0,0,0,0.50)',
    },
    'black-85': {
      variant: 'darkener.default',
      bg: 'rgba(0,0,0,0.85)',
    },
    'white-25': {
      variant: 'darkener.default',
      bg: 'rgba(255,255,255,0.50)',
    },
    'white-50': {
      variant: 'darkener.default',
      bg: 'rgba(255,255,255,0.50)',
    },
  },
  forms: {
    label: {
      display: 'block',
      color: 'white',
      fontSize: 0,
      my: '10px',
    },
    input: {
      default: {
        padding: 0,
        color: 'white',
        display: 'block',
        border: 'none',
        fontFamily: 'body',
        fontSize: 3,
        borderRadius: 0,
        borderBottom: '1px solid',
        borderBottomColor: 'white',
        height: '23px',
        lineHeight: '23px',
        paddingBottom: '3px',
        transitionProperty: 'color, border-color',
        transitionDuration: '0.2s',
        transitionTimingFunction: 'ease-in',
        ':focus': {
          outline: 'none',
        },
        '::placeholder': {
          color: 'white',
        },
        ':disabled': {
          color: 'grayDarker',
          borderBottomColor: 'grayDarker',
        },
        ':disabled::placeholder': {
          color: 'grayDarker',
        },
      },
      hasError: {
        variant: 'forms.input.default',
        '::placeholder': {
          color: 'red',
        },
        color: 'red',
        borderBottomColor: 'red',
      },
    },
  },
  styles: {
    root: {
      variant: 'text.copy',
      bg: 'white',
    },
    footer: {
      // variant: 'container.content',
      borderTop: '1px solid',
      borderTopColor: 'grayLight',
      paddingTop: 4,
      p: {
        color: 'grayDark',
      },
      strong: {
        fontWeight: 'heading',
      },
    },
    h1: {
      color: 'heading',
      variant: 'text.heading',
      fontSize: 5,
    },
    h2: {
      textTransform: 'uppercase',
      variant: 'text.heading',
      fontSize: 5,
      color: 'black',
      fontWeight: 'heading',
    },
    h3: {
      textTransform: 'uppercase',
      variant: 'text.heading',
      fontSize: 6,
      color: 'black',
      '& + h4': {
        marginTop: '-1.2em',
      },
    },
    h4: {
      variant: 'text.copy',
      // color: 'grayDark',
      // fontSize: 2,
    },
    h5: {
      variant: 'text.heading',
      fontWeight: 'body',
      color: 'grayDark',
      textTransform: 'uppercase',

      fontSize: 5,
    },
    h6: {
      color: 'heading',
      variant: 'text.heading',
      fontSize: 0,
    },
    p: {
      color: 'grayDark',
      variant: 'text.copy',
      fontSize: 3,
      a: {
        variant: 'styles.a',
      },
    },
    a: {
      color: 'inherit',
      ':hover, :focus': {
        color: 'grayDark',
        outline: 'none',
      },
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
};
